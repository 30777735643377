<template>
  <span class="p-input-icon-right w-full" :class="{ 'p-invalid': isSupplierNumberInUse }">
    <i v-if="loading && supplierNumber" class="pi pi-spin pi-spinner"></i>
    <i v-else-if="!loading && supplierNumber && isSupplierNumberInUse" class="pi pi-times"></i>
    <i v-else-if="!loading && supplierNumber && !isSupplierNumberInUse" class="pi pi-check"></i>
    <FloatLabel
      id="supplier-number"
      ref="inputRef"
      type="text"
      v-model:value="supplierNumberComputed"
      data-testid="supplier-number"
      class="inputfield w-full pt-2"
      :class="{ 'p-invalid': isSupplierNumberInUse || val.$error }"
      :placeholder="placeholderComputed"
      v-debounce:400="checkIfSupplierNumberIsInUse"
      maxlength="8000"
      :label="t('supplier.number.label')"
      :selectAllOnFocus="true"
      :isRequired="true"
    />
  </span>

  <small class="p-error" v-if="val.$error" data-testid="supplier-number-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed, ref, watch } from "vue";
import { useSupplier } from "@/repositories/supplier/SupplierService";
import useVuelidate from "@vuelidate/core";
import { helpers, requiredIf } from "@vuelidate/validators";

const { getSupplierNumberInUse } = useSupplier();
const { t } = useI18n();

const props = defineProps<{
  supplierNumber: string;
  isEdit: boolean;
}>();

const emit = defineEmits<{
  (e: "update:supplierNumber", value: string | null): void;
}>();

const isSupplierNumberInUse = ref<boolean>(false);
const loading = ref<boolean>(false);
const originalSupplierNumber = ref<string>("");
const isOriginalSupplierNumberSet = ref<boolean>(false);
const inputRef = ref();

watch(
  () => props.supplierNumber,
  (newValue) => {
    if (!isOriginalSupplierNumberSet.value) {
      originalSupplierNumber.value = newValue;
      isOriginalSupplierNumberSet.value = true;
    }
  }
);

const supplierNumberComputed = computed<string>({
  get: () => {
    return props.supplierNumber ?? "";
  },
  set: (value) => {
    emit("update:supplierNumber", value);
  },
});

const placeholderComputed = computed<string>(() => {
  return props.isEdit
    ? t("placeholder.type", { property: t("supplier.number.label").toLowerCase() })
    : t("supplier.number.placeholder");
});

const checkIfSupplierNumberIsInUse = async () => {
  if (props.isEdit && props.supplierNumber === originalSupplierNumber.value) {
    isSupplierNumberInUse.value = false;
    return;
  }
  if (props.supplierNumber === "") {
    isSupplierNumberInUse.value = false;
    return;
  }
  loading.value = true;
  isSupplierNumberInUse.value = await getSupplierNumberInUse(props.supplierNumber);
  loading.value = false;
};

const rules = {
  isSupplierNumberInUse: {
    supplierNumberInUse: helpers.withMessage(
      t("validations.supplier-number-already-in-use"),
      (value: boolean) => !value
    ),
  },
  supplierNumber: {
    required: requiredIf(props.isEdit),
  },
};

const val = useVuelidate(rules, { isSupplierNumberInUse, supplierNumber: supplierNumberComputed });

const focus = () => {
  if (inputRef.value.$el) {
    inputRef.value.$el.focus();
  }
};
defineExpose({
  focus,
});
</script>
