<template>
  <div class="p-float-label">
    <Select
      id="supplier-currency"
      :options="currencies"
      optionLabel="name"
      data-testid="supplier-currency"
      v-model="selectedCurrency"
      class="w-full"
      :class="{ 'p-invalid': val.currency.$error }"
      :placeholder="t('placeholder.select', { property: t('supplier.currency').toLowerCase() })"
      @show="isActive = true"
      @hide="isActive = false"
      @focus="isDropdownFocused = true"
      @blur="isDropdownFocused = false"
      @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
      pt:list:data-testid="supplier-currency-list"
      pt:root:class="shadow-none"
    />
    <label
      for="supplier-currency"
      class="c-under-construction c-required c-floating-label -mt-3"
      :class="{ 'c-focused': isDropdownFocused }"
    >
      {{ t(`supplier.currency`) }}
    </label>
    <small id="supplier-currency-help" data-testid="supplier-currency-error" class="p-error" v-if="val.$error">{{
      val.$errors[0].$message
    }}</small>
  </div>
</template>

<script setup lang="ts">
import { useCurrency } from "@/api/currency/CurrencyService";
import { Currency } from "@/models/currency/Currency";
import useVuelidate from "@vuelidate/core";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";

const { t } = useI18n();

const props = defineProps<{
  currency: Currency | null;
}>();

const emit = defineEmits<{
  (e: "update:currency", value: Currency): void;
}>();

const isActive = ref(false);

const selectedCurrency = computed<Currency>({
  get: () => {
    return props.currency ?? ({} as Currency);
  },
  set: (value) => {
    emit("update:currency", value);
  },
});

const { getAllCurrencies } = useCurrency();
const currencies = ref<Currency[]>([]);

const fetchCurrencies = async () => {
  currencies.value = await getAllCurrencies();
};
onMounted(fetchCurrencies);

const rules = {
  currency: {
    required,
  },
};

const isDropdownFocused = ref(false);
const val = useVuelidate(rules, props);
</script>
