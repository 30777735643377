<template>
  <BeforeUnloadBrowserTab :hasUpdates="hasUpdates" />
  <div class="c-supplier-edit">
    <SupplierHeader
      :editMode="true"
      :hasUpdates="hasUpdates"
      :unsavedChangesDialogVisible="unsavedChangesDialogVisible"
      @onCancel="onCancel"
      @saveClicked="onUpdateSupplier"
      @onDelete="onDeleteSupplier"
      @dialogCancelBtnClicked="stayOnPage"
      @dialogDiscardBtnClicked="routeToSupplierList"
      :collapsedPanels="panelCollapsed"
      @onToggleAllPanels="onToggleAllPanels()"
    />

    <div class="grid grid-cols-12 gap-4 mb-4">
      <Card class="col-span-12 lg:col-span-8 c-card-font">
        <template #content>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-6">
              <div class="mb-4">
                <SupplierNumber
                  v-model:supplierNumber="supplier.supplierNumber"
                  :isEdit="true"
                  ref="supplierNumberRef"
                />
              </div>

              <div class="mb-2">
                <Country v-model:countryIso="supplier.countryIso" :countryOptions="countryOptions" />
              </div>

              <div class="mb-2 mt-4">
                <DocumentLanguage v-model:documentLanguage="supplier.documentLanguage" />
              </div>
            </div>

            <div class="col-span-6">
              <div class="-mr-3 my-4">
                <Name v-model:name="supplier.name" :focus-input="true" />
              </div>

              <div class="-mr-3 mb-4">
                <Email v-model:email="supplier.email" />
              </div>
              <div class="-mr-3 mb-4">
                <Clients v-model:selectedClientIds="supplier.clientIds" />
              </div>
            </div>
          </div>
        </template>
      </Card>

      <Card class="col-span-12 lg:col-span-4">
        <template #content>
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12">
              <div class="-mr-3 my-4">
                <BusinessNumber v-model:businessNumber="supplier.businessNumber" />
              </div>

              <div class="mb-4">
                <PhoneNumber v-model:phoneNumber="supplier.phoneNumber" />
              </div>

              <div class="mb-2 mt-4">
                <PurchasePermit v-model:purchasePermit="supplier.purchasePermit" />
              </div>
            </div>
          </div>
        </template>
      </Card>
    </div>

    <Panel
      :header="t('supplier.addresses.label')"
      toggleable
      class="mb-4"
      :collapsed="panelCollapsed"
      id="addressPanelCollapsed"
      data-testid="addresses-panel"
    >
      <div class="grid grid-cols-12 gap-4 mb-4">
        <div class="col-span-12 lg:col-span-6 xl:col-span-4 lg:border-r" data-testid="company-address">
          <label class="inline-block mb-3 font-semibold">{{ t("supplier.addresses.company-address") }}</label>
          <Address
            :selected-country="supplier.countryIso"
            :address="supplier.companyAddress"
            :countryOptions="countryOptions"
            @setAddress="setCompanyAddress"
            :address-type="addressTypeCompany"
          />
        </div>

        <div class="col-span-12 lg:col-span-6 xl:col-span-4 xl:border-r" data-testid="return-address">
          <label class="inline-block mb-3 font-semibold">{{ t("supplier.addresses.return-address") }}</label>
          <Address
            :selected-country="supplier.countryIso"
            :address="supplier.returnAddress"
            :countryOptions="countryOptions"
            @setAddress="setReturnAddress"
            :address-type="addressTypeReturn"
          />
        </div>

        <div class="col-span-12 lg:col-span-6 xl:col-span-4" data-testid="invoice-address">
          <label class="inline-block mb-3 font-semibold">
            {{ t("supplier.addresses.invoice-address") }}
          </label>
          <Address
            :selected-country="supplier.countryIso"
            :address="supplier.invoiceAddress"
            :countryOptions="countryOptions"
            @setAddress="setInvoiceAddress"
            :address-type="addressTypeInvoice"
          />
        </div>
      </div>
    </Panel>

    <Panel
      :header="t('supplier.contacts.header')"
      toggleable
      :collapsed="panelCollapsed"
      class="mb-4"
      id="contactsPanelCollapsed"
      data-testid="contacts-panel"
    >
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12">
          <div class="field">
            <ContactList v-model:contacts="supplier.contacts" />
          </div>
        </div>
      </div>
    </Panel>

    <Panel
      :header="t('supplier.condition-and-freight')"
      toggleable
      :collapsed="panelCollapsed"
      class="mb-4"
      id="conditionsAndFreightPanelCollapsed"
      data-testid="conditions-and-freight-panel"
    >
      <div class="grid grid-cols-12 gap-4">
        <div class="col-span-12 lg:col-span-6 lg:border-r">
          <div class="grid grid-cols-4 gap-4">
            <div class="mb-2 mt-4">
              <Currency v-model:currency="supplier.currency" />
            </div>
            <div class="mb-2 mt-4">
              <DefaultPaymentTerm v-model:paymentTermId="supplier.defaultPaymentTermId" />
            </div>
            <div class="mb-2 mt-4">
              <Incoterms v-model:incoterms="supplier.incoterms" />
            </div>
            <div class="mb-2 mt-4 mr-8">
              <EstimatedDeliveryDays v-model:estimatedDeliveryDays="supplier.estimatedDeliveryDays" />
            </div>
          </div>
        </div>
        <div class="col-span-12 lg:col-span-6">
          <div class="grid grid-cols-3 gap-4">
            <div class="mb-2 mt-4">
              <FreightMethod v-model:freightMethodId="supplier.freightMethodId" />
            </div>
            <div class="mb-2 mt-4 mr-6">
              <FixedShipping v-model:fixedShipping="supplier.fixedShipping" />
            </div>
            <div class="mb-2 mt-4 mr-6">
              <FreeShipping v-model:freeFreight="supplier.freeShippingFrom" />
            </div>
          </div>
        </div>
      </div>
    </Panel>
  </div>

  <div v-if="loadFailed">
    <Card class="c-card-font mx-6">
      <template #content>
        <div class="">
          <div>{{ t("supplier.load-error") }} {{ errorReason }}</div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref, nextTick } from "vue";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import useValidate from "@vuelidate/core";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";
import { BeforeUnloadBrowserTab } from "@cumulus/components";
import { useI18n } from "vue-i18n";

import { Supplier } from "@/repositories/supplier/model/Supplier";
import { Country as CountryModel } from "@/models/country/Country";
import { useSupplier } from "@/repositories/supplier/SupplierService";
import { useCountry } from "@/api/country/CountryService";
import { supplierAddressFunctions } from "../SupplierAddressFunctions";
import BusinessNumber from "../components/BusinessNumber.vue";
import SupplierHeader from "../components/SupplierHeader.vue";
import FreightMethod from "../components/FreightMethod.vue";
import Country from "../components/Country.vue";
import PhoneNumber from "../components/PhoneNumber.vue";
import Address from "../components/Address.vue";
import Incoterms from "../components/Incoterms.vue";
import Email from "../components/Email.vue";
import DefaultPaymentTerm from "../components/DefaultPaymentTerm.vue";
import ContactList from "../components/ContactList.vue";
import PurchasePermit from "../components/PurchasePermit.vue";
import SupplierNumber from "../components/SupplierNumber.vue";
import Name from "../components/Name.vue";
import Currency from "../components/Currency.vue";
import DocumentLanguage from "../components/DocumentLanguage.vue";
import Clients from "../components/Clients.vue";
import FixedShipping from "../components/FixedShipping.vue";
import FreeShipping from "../components/FreeShipping.vue";
import EstimatedDeliveryDays from "../components/EstimatedDeliveryDays.vue";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const toast = useCumulusToast(useToast());
const { updateSupplier, deleteSupplier, getSupplier } = useSupplier();
const { getAllCountries } = useCountry();

const loadFailed = ref(false);
const errorReason = ref("");
const isLoading = ref(true);
const countryOptions = ref<CountryModel[]>([]);
const addressTypeInvoice = ref("invoice");
const addressTypeReturn = ref("return");
const addressTypeCompany = ref("company");
const panelCollapsed = ref(false);

const supplier = ref<Supplier>(new Supplier());

const previouslyFocusedInput = ref<HTMLInputElement | null>(null);
const unsavedChangesDialogVisible = ref(false);
const initialSupplier = ref<Supplier | null>(null);
const supplierNumberRef = ref();
const confirmedDiscard = ref(false);

const { setCompanyAddress, setReturnAddress, setInvoiceAddress } = supplierAddressFunctions(supplier);

const val = useValidate();

const routeToSupplierList = () => {
  confirmedDiscard.value = true;
  if (window.history.state.back === null || window.history.state.back.indexOf("/supplier/search") === -1) {
    router.push({ name: "supplier-search", query: { search: "" } });
  } else {
    const url = new URL(window.location.origin + window.history.state.back);
    url.searchParams.set("focusResult", supplier.value.id);
    router.push({ path: window.history.state.back, query: paramsToObject(url.searchParams) });
  }
};

const paramsToObject = (entries: URLSearchParams) => {
  const result = {} as Record<string, string>;
  for (const [key, value] of entries) {
    result[key] = value;
  }
  return result;
};

const onUpdateSupplier = async () => {
  val.value.$touch();
  await val.value.$validate();
  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  await updateSupplier(supplier.value);

  toast.add({
    severity: "success",
    summary: t("supplier.toast.update-success.summary"),
    detail: t("supplier.toast.update-success.detail", { name: supplier.value.name }),
    closable: true,
  });

  routeToSupplierList();
};

const fetchSupplierById = async () => {
  const id = route.params.id as string;

  try {
    supplier.value = await getSupplier(id);
    initialSupplier.value = cloneDeep(supplier.value);
  } catch (error) {
    loadFailed.value = true;
  } finally {
    isLoading.value = false;
  }
};

const fetchCountryData = async () => {
  countryOptions.value = await getAllCountries();
};

const onToggleAllPanels = () => {
  panelCollapsed.value = !panelCollapsed.value;
};

onMounted(() => {
  document.addEventListener("keydown", handleKeydown);
  Promise.all([fetchSupplierById(), fetchCountryData()]);
  focusInput();
});

const handleKeydown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    if (unsavedChangesDialogVisible.value) {
      stayOnPage();
    } else onCancel();
  } else if (event.ctrlKey && event.key === "i") {
    if (unsavedChangesDialogVisible.value) {
      routeToSupplierList();
    }
  }
};

onBeforeUnmount(() => {
  document.removeEventListener("keydown", handleKeydown);
});

const onDeleteSupplier = async () => {
  await deleteSupplier(supplier.value.id);

  toast.add({
    severity: "success",
    summary: t("supplier.toast.delete-success.summary"),
    closable: true,
  });

  routeToSupplierList();
};

const hasUpdates = computed(() => {
  return !isEqual(supplier.value, initialSupplier.value);
});

const onCancel = () => {
  previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
  if (hasUpdates.value === true) {
    unsavedChangesDialogVisible.value = true;
  } else {
    routeToSupplierList();
  }
};

const stayOnPage = () => {
  unsavedChangesDialogVisible.value = false;
  if (previouslyFocusedInput.value) {
    previouslyFocusedInput.value.focus();
  }
};

const focusInput = async () => {
  await nextTick();
  supplierNumberRef.value.focus();
};

window.addEventListener("beforeunload", (e) => {
  if (hasUpdates.value) {
    e.preventDefault();
    e.returnValue = t("common.unsaved-changes-header");
  }
});

onBeforeRouteLeave((_to, _from, next) => {
  if (hasUpdates.value && !confirmedDiscard.value) {
    unsavedChangesDialogVisible.value = true;
    next(false);
  } else {
    next();
  }
});
</script>

<style scoped lang="scss">
.c-supplier-edit {
  margin: var(--default-content-margin);
  margin-bottom: 7rem;
}

.c-card-font {
  font-size: 12.8px;
}

.c-spinner-container {
  position: relative;
  top: 175px;
}
:deep(input#supplier-fixed-shipping) {
  padding: 0.2rem;
  font-size: 1.2rem;
}
:deep(input#supplier-estimated-delivery) {
  padding: 0.2rem;
  font-size: 1.2rem;
}
:deep(input#supplier-free-shipping) {
  padding: 0.2rem;
  font-size: 1.2rem;
}
</style>
