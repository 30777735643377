<template>
  <div class="field">
    <div class="p-float-label">
      <Select
        id="supplier-incoterms"
        :options="incotermsOptions"
        optionLabel="code"
        data-testid="supplier-incoterms"
        v-model="selectedIncoterms"
        class="w-full"
        :placeholder="t('placeholder.select', { property: t('supplier.incoterms').toLowerCase() })"
        @show="isActive = true"
        @hide="isActive = false"
        @focus="isDropdownFocused = true"
        @blur="isDropdownFocused = false"
        @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
        pt:list:data-testid="supplier-incoterms-list"
        pt:root:class="shadow-none"
      />
      <label
        for="supplier-incoterms"
        class="c-under-construction c-floating-label -mt-3"
        :class="{ 'c-focused': isDropdownFocused }"
      >
        {{ t("supplier.incoterms") }}
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { incotermsRepository } from "@/repositories/incoterms/IncotermsRepository";
import { computed, onMounted, ref } from "vue";
import { Incoterms } from "@/repositories/supplier/model/Incoterms";

const props = defineProps<{
  incoterms: Incoterms | null;
}>();

const emit = defineEmits<{
  (e: "update:incoterms", value: Incoterms | null): void;
}>();

const { t } = useI18n();
const incotermsOptions = ref<Incoterms[]>([]);

const isActive = ref(false);

const selectedIncoterms = computed<Incoterms | null>({
  get: () => {
    let incoterms: Incoterms | null = null;
    incotermsOptions.value.find((i) => {
      if (i.code === props.incoterms?.code) {
        incoterms = i;
      }
    });
    return incoterms;
  },
  set: (value) => {
    emit("update:incoterms", value);
  },
});

const fetchIncoterms = () => {
  incotermsRepository.getAll().then((response) => {
    response.map((c) => {
      incotermsOptions.value.push({
        code: c.code,
        description: c.description,
      } as Incoterms);
    });
  });
};
onMounted(fetchIncoterms);

const isDropdownFocused = ref(false);
</script>
