<template>
  <div class="p-float-label">
    <MultiSelect
      id="supplier-clients"
      v-model="selectedClientsComputed"
      :options="clients"
      class="p-select"
      optionLabel="name"
      optionValue="id"
      data-testid="supplier-clients"
      display="chip"
      :placeholder="t('placeholder.select', { property: t('supplier.clients').toLowerCase() })"
      @show="isActive = true"
      @hide="isActive = false"
      @focus="isDropdownFocused = true"
      @blur="isDropdownFocused = false"
      @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
      pt:list:data-testid="supplier-clients-list"
      pt:root:class="shadow-none"
    />
    <label
      for="supplier-clients c-required c-floating-label"
      :class="{ 'c-focused': isDropdownFocused || props.selectedClientIds.length !== 0 }"
    >
      {{ t(`supplier.clients`) }}
    </label>
  </div>
</template>

<script setup lang="ts">
import { useClient } from "@/api/client/ClientService";
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { Client } from "@/models/client/Client";

const { t } = useI18n();
const { getAllClients } = useClient();

const props = defineProps<{
  selectedClientIds: string[];
}>();

const emit = defineEmits<{
  (e: "update:selectedClientIds", value: string[]): void;
}>();

const clients = ref<Client[]>([]);

const isActive = ref(false);

const fetchClients = async () => {
  clients.value = await getAllClients();
};
onMounted(fetchClients);

const selectedClientsComputed = computed<string[]>({
  get: () => {
    return props.selectedClientIds ?? [];
  },
  set: (value) => {
    emit("update:selectedClientIds", value);
  },
});

const isDropdownFocused = ref(false);
</script>
